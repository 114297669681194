import {
  ApiId,
  AppRecord,
  CommonApiValue,
  CommonFormValue,
  IMany2One,
  IOne2Many,
  ITemplateSingleItem,
  Many2One,
  One2Many,
  TemplateSingleItem,
} from "@app/models";
import { Boxed, Unboxed } from "ngrx-forms";
import { DiveOperationCommon } from "./common.model";

export const CHECKLIST_LINE_MODEL = "checklist.line";

export const CHECKLIST_TEMPLATE_MODEL = "checklist.template";

export interface IChecklistItemForm extends ITemplateSingleItem {}

export interface IChecklistLineForm {
  name: string;
  checklist_id: ApiId;
  template_id: Boxed<IMany2One>;
  signature: string;
  signed_by: string;
  item_ids: IOne2Many<IChecklistItemForm>;
}

export class ChecklistItem
  extends TemplateSingleItem
  implements IChecklistItemForm {}

export class ChecklistLine
  extends DiveOperationCommon
  implements Unboxed<IChecklistLineForm>
{
  public checklist_id: ApiId;
  public template_id: Many2One;
  public signature: string;
  public signed_by: string;
  public item_ids: One2Many<ChecklistItem>;

  constructor(data: Partial<ChecklistLine> = {}) {
    super(data);

    this.checklist_id = data.checklist_id;
    this.template_id = data.template_id ? new Many2One(data.template_id) : null;
    this.signature = data.signature;
    this.signed_by = data.signed_by;
    this.item_ids = new One2Many(
      data.item_ids ? data.item_ids.map((item) => new ChecklistItem(item)) : []
    );
  }
}

export class ChecklistTemplate
  extends AppRecord
  implements Partial<Unboxed<IChecklistLineForm>>
{
  public sequence: number;
  public item_ids: One2Many<ChecklistItem>;

  constructor(data: Partial<ChecklistTemplate> = {}) {
    super(data);

    this.item_ids = new One2Many(
      data.item_ids ? data.item_ids.map((item) => new ChecklistItem(item)) : []
    );
  }
}

export class ChecklistLineFormValue
  extends CommonFormValue<ChecklistLineFormValue>
  implements IChecklistLineForm
{
  public name: string;
  public checklist_id: ApiId;
  public template_id: Boxed<IMany2One>;
  public signature: string;
  public signed_by: string;
  public item_ids: IOne2Many<IChecklistItemForm>;

  constructor(data: Partial<Unboxed<IChecklistLineForm>> = {}) {
    super();

    this.setProperty("name", data);
    this.setProperty("checklist_id", data);
    this.setMany2One("template_id", data);
    this.setProperty("signature", data);
    this.setProperty("signed_by", data);
    this.setOne2Many("item_ids", data);
  }
}

export class ChecklistLineApiValue
  extends CommonApiValue<ChecklistLineApiValue>
  implements Unboxed<IChecklistLineForm>
{
  public name: string;
  public checklist_id: ApiId;
  public template_id: IMany2One;
  public signature: string;
  public signed_by: string;
  public item_ids: One2Many<ChecklistItem>;

  constructor(data: Partial<IChecklistLineForm> = {}) {
    super();

    this.setProperty("name", data);
    this.setProperty("checklist_id", data);
    this.setMany2One("template_id", data);
    this.setProperty("signature", data);
    this.setProperty("signed_by", data);
    this.setOne2Many("item_ids", data);
  }
}
