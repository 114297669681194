import { ApiId, One2Many } from "./api.model";
import { ChecklistLine } from "./checklist-line.model";
import { DiveOperationCommon } from "./common.model";

export const CHECKLIST_MODEL = "checklist.checklist";

export interface IChecklistForm {
  operation_id: ApiId;
}

export class Checklist extends DiveOperationCommon implements IChecklistForm {
  public line_ids: One2Many<ChecklistLine>;

  constructor(data: Partial<Checklist> = {}) {
    super(data);

    this.line_ids = new One2Many(
      data.line_ids ? data.line_ids.map((line) => new ChecklistLine(line)) : []
    );
  }
}
