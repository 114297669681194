import { AppRecord } from "@app/models";

export const USER_MODEL = "res.users";

export class ResUser extends AppRecord {
  public email: string;
  public dopa_role: string;

  constructor(data: Partial<ResUser> = {}) {
    super(data);
    this.email = data.email;
    this.dopa_role = data.dopa_role;
  }
}
