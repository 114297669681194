import { AppRecord } from "./api.model";

export const PARTNER_MODEL = "res.partner";

export enum ResPartnerType {
  contact = "contact",
  invoice = "invoice",
  delivery = "delivery",
  other = "other",
  private = "private",
}

export class ResPartner extends AppRecord {
  public is_company: boolean;
  public type: ResPartnerType;

  constructor(data: Partial<ResPartner> = {}) {
    super(data);

    this.is_company = data.is_company;
    this.type = data.type;
  }
}
