import { AppRecord } from "./api.model";

export const DIVE_SITE_MODEL = "dive.site";

export class DiveSite extends AppRecord {
  public email: string;
  public partner_id: number;

  constructor(data: Partial<DiveSite> = {}) {
    super(data);
    this.partner_id = data.partner_id;
    this.email = data.email;
  }
}
