import { ApiId, IOne2ManyItem, One2ManyItem } from "@app/models";

export const TIMESHEET_MODEL = "dive.time.sheet";

export interface ITimesheetForm extends IOne2ManyItem {
  operation_id: ApiId;
  log_line_id: ApiId;
  time: string;
  description: string;
  is_auto: boolean;
}

export class Timesheet extends One2ManyItem implements ITimesheetForm {
  public operation_id: ApiId;
  public log_line_id: ApiId;
  public time: string;
  public description: string;
  public is_auto: boolean;
  constructor(data: Partial<Timesheet> = {}) {
    super(data);

    if (!this.hasOwnProperty("name")) {
      this.name = null;
    }

    this.operation_id = data.operation_id ?? null;
    this.log_line_id = data.log_line_id ?? null;
    this.time = data.time ?? null;
    this.description = data.description ?? null;
    this.is_auto = data.is_auto ?? false;
  }
}
