import { ApiId } from "@app/models";
import { AppRecord } from "./api.model";

export const ATTACHMENT_MODEL = "ir.attachment";

export const ATTACHMENT_THUMBNAIL = "assets/thumbnail.svg";

export enum AttachmentStatus {
  Pending = "pending",
  Loading = "loading",
  Success = "success",
  Error = "error",
}

export interface IAttachmentForm {
  _id: string;
  name: string;
  mimetype: string;
  description: string;
  res_model?: string;
  res_id?: ApiId;
}

export class Attachment extends AppRecord implements IAttachmentForm {
  public mimetype: string;
  public description: string;
  public res_model: string;
  public res_id: ApiId;
  public error: any;
  public status: AttachmentStatus;
  public thumbnail: string = null;

  constructor(data: Partial<Attachment> = {}) {
    super(data);
    this.mimetype = data.mimetype || "";
    this.description = data.description;
    this.res_model = data.res_model;
    this.res_id = data.res_id;
    this.thumbnail = data.thumbnail || ATTACHMENT_THUMBNAIL;
    this.error = data.error || null;
    this.status = data.status || AttachmentStatus.Pending;
  }
}
