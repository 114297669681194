import { box, Boxed, isBoxed, unbox } from "ngrx-forms";
import { Observable } from "rxjs";
import * as uuid from "uuid";

export const OFFLINE_QUEUE_MODEL = "sync";

export type ReplaceType<T, K extends keyof T, N> = Omit<T, K> & { [P in K]: N };

export type ApiId = number | string;

export interface Dictionary {
  [key: string]: any;
}

export interface IOnlineId {
  id: number;
}

export interface IMany2One {
  id: number;
  name: string;
}

export class OnlineId {
  public id: number;
  constructor(data: IOnlineId) {
    this.id = data.id ?? null;
  }
}

// TODO online
export class Many2One implements IMany2One {
  id: number;
  name: string;

  constructor(data: IMany2One) {
    this.id = data.id ?? null;
    this.name = data.name ?? null;
    Object.setPrototypeOf(this, Many2One.prototype);
  }
}

export interface INewRecord {
  _id: string;
  [key: string]: any;
}

export interface IAppRecord extends INewRecord {
  id?: number;
  name?: string;
}

export interface IOne2ManyItem extends IAppRecord {
  id: number;
  name: string;
}

export class One2ManyItem implements IOne2ManyItem {
  _id: string;
  id: number;
  name: string;

  constructor(data: Partial<One2ManyItem> = {}) {
    this._id = data._id ?? (data.id ? data.id.toString() : uuid.v4());
    if (data.id !== undefined) {
      this.id = data.id;
    }
    if (data.name !== undefined) {
      this.name = data.name;
    }
  }
}

export type AppRecord$ = Partial<{
  [K in keyof IAppRecord]: Observable<IAppRecord[K]>;
}>;

export class AppRecord implements IAppRecord {
  _id: string;
  id: number;
  name: string;

  constructor(data: Partial<AppRecord> = {}) {
    this._id = data._id ?? (data.id ? data.id.toString() : uuid.v4());
    if (data.id !== undefined) {
      this.id = data.id;
    }
    if (data.name !== undefined) {
      this.name = data.name;
    }
  }
}

export interface IOne2Many<T extends IOne2ManyItem> extends Array<T> {}

export class One2Many<T extends IOne2ManyItem> extends Array<T> {
  constructor(iterable?: Iterable<T> | null | undefined) {
    if (iterable && typeof iterable[Symbol.iterator] === "function") {
      super(...iterable);
    } else {
      super();
    }
    Object.setPrototypeOf(this, One2Many.prototype);
  }

  // findById(id: number): T | undefined {
  //   return this.find((item) => item.id === id);
  // }
}

export interface IMany2ManyValues {
  [index: number]: { id: number };
}

export interface IMany2Many<T extends IMany2One> extends Array<T> {}

export type ConditionalKeys<T, Condition> = {
  [K in keyof T]: T[K] extends Condition ? K : never;
}[keyof T];

export type PrimitiveKeys<T> = ConditionalKeys<
  T,
  string | boolean | number | Blob
>;

export type Many2OneKeys<T> = ConditionalKeys<T, IMany2One>;

export type One2ManyKeys<T> = ConditionalKeys<T, IOne2Many<IOne2ManyItem>>;

export type Many2ManyKeys<T> = ConditionalKeys<T, IMany2Many<IMany2One>>;

export type BoxedMany2OneKeys<T> = ConditionalKeys<T, Boxed<IMany2One>>;

export type BoxedMany2ManyKeys<T> = ConditionalKeys<
  T,
  Boxed<IMany2Many<IMany2One>>
>;

export class Many2Many<T extends IMany2One> extends Array<T> {
  constructor(iterable?: Iterable<T> | null | undefined) {
    if (iterable && typeof iterable[Symbol.iterator] === "function") {
      super(...iterable);
    } else {
      super();
    }
    Object.setPrototypeOf(this, Many2Many.prototype);
  }

  // findById(id: number): T | undefined {
  //   return this.find((item) => item.id === id);
  // }
}

export interface IOfflineRecord {
  _id: string;
  _rev: string;
  [key: string]: any;
}

export interface IOfflineDoc {
  doc: IOfflineRecord;
}

export interface IOfflineAllDocs {
  rows: IOfflineDoc[];
}

export interface IOfflineDeleted {
  _id: string;
  _rev: string;
  _deleted: true;
}

export class CommonFormValue<G> {
  setProperty<K extends PrimitiveKeys<G>, T>(
    key: K,
    data: T,
    defaultValue = null
  ) {
    if (data.hasOwnProperty(key)) {
      this[key as string] = data[key as string];
    } else if (defaultValue) {
      this[key as string] = defaultValue;
    }
  }

  setMany2One<K extends BoxedMany2OneKeys<G>, T>(key: K, data: T): void {
    if (data.hasOwnProperty(key)) {
      const value: IMany2One | null = data[key as string]
        ? { id: data[key as string].id, name: data[key as string].name }
        : null;
      this[key as string] = box(value);
    }
  }

  setOne2Many<K extends One2ManyKeys<G>, T>(key: K, data: T): void {
    if (data.hasOwnProperty(key)) {
      const value: IOne2Many<IOne2ManyItem> | null = data[key as string]
        ? [
            ...data[key as string].map((item: IOne2ManyItem) => {
              const newItem: IOne2ManyItem = { ...item };
              for (const field in newItem) {
                if (
                  newItem[field] !== null &&
                  typeof newItem[field] === "object"
                ) {
                  newItem[field] = box({ ...newItem[field] });
                }
              }
              return newItem;
            }),
          ]
        : null;
      this[key as string] = value;
    }
  }

  setMany2Many<K extends BoxedMany2ManyKeys<G>, T>(key: K, data: T): void {
    if (data.hasOwnProperty(key)) {
      const values: IMany2Many<IMany2One> = data[key as string]
        ? [
            ...data[key as string].map((item: IMany2One) => ({
              id: item.id,
              name: item.name,
            })),
          ]
        : [];
      this[key as string] = box(values);
    }
  }
}

export class CommonApiValue<G> {
  setProperty<K extends PrimitiveKeys<G>, T>(key: K, data: T) {
    if (data.hasOwnProperty(key)) {
      this[key as string] = data[key as string];
    }
  }

  setMany2One<K extends Many2OneKeys<G>, T>(key: K, data: T): void {
    if (data.hasOwnProperty(key)) {
      const value: Many2One = unbox(data[key as string])
        ? new Many2One(unbox(data[key as string]))
        : null;
      this[key as string] = value;
    }
  }

  setOne2Many<K extends One2ManyKeys<G>, T>(key: K, data: T): void {
    if (data.hasOwnProperty(key)) {
      const value: One2Many<IOne2ManyItem> = data[key as string]
        ? new One2Many(
            data[key as string].map((item: IOne2ManyItem) => {
              const newItem: IOne2ManyItem = { ...item };
              for (const field in newItem) {
                if (isBoxed(newItem[field])) {
                  const unboxedValue = unbox(newItem[field]);
                  if (unboxedValue !== null) {
                    newItem[field] = Object.setPrototypeOf(
                      { ...unboxedValue },
                      Many2One.prototype
                    );
                  } else {
                    newItem[field] = unboxedValue;
                  }
                }
              }
              return newItem;
            })
          )
        : null;
      this[key as string] = value;
    }
  }

  setMany2Many<K extends Many2ManyKeys<G>, T>(key: K, data: T): void {
    if (data.hasOwnProperty(key)) {
      const values: Many2Many<Many2One> = unbox(data[key as string])
        ? new Many2Many(unbox(data[key as string]))
        : [];
      this[key as string] = values;
    }
  }
}

export interface Datas {
  id: number;
  name: string;
  datas: string | Blob;
}

export type SyncStatus = "pending" | "syncing" | "success" | "error";

export interface EnqueueOptions {
  model: string;
  id: ApiId;
}

export interface EnqueuedRequest {
  method: "create" | "update" | "delete";
  model: string;
  id: ApiId;
  values: any;
  sequence?: number;
}

export interface _SyncDoc {
  _id: string;
  requests: EnqueuedRequest[];
}

export interface SyncInfo {
  model?: string;
  id?: string;
  status: SyncStatus;
  errors: unknown[];
}

export interface EnqueuedGroup {
  _id: string;
  user_id: number;
  company_id: number;
  queue: EnqueuedRequest[];
  errors?: unknown[];
  status?: SyncStatus;
}

export interface ITemplateSingleItem extends IOne2ManyItem {
  type: string;
  value_1_char: string;
  value_1_text: string;
  value_1_time: string;
  value_1_date: string;
  value_1_float: number;
  value_1_integer: number;
  value_1_boolean: boolean;
}

export class TemplateSingleItem
  extends One2ManyItem
  implements ITemplateSingleItem
{
  public type: string;
  public value_1_char: string;
  public value_1_text: string;
  public value_1_time: string;
  public value_1_date: string;
  public value_1_float: number;
  public value_1_integer: number;
  public value_1_boolean: boolean;

  constructor(data: Partial<ITemplateSingleItem> = {}) {
    super(data);
    this.type = data.type;
    this.value_1_char = data.value_1_char;
    this.value_1_text = data.value_1_text;
    this.value_1_time = data.value_1_time;
    this.value_1_date = data.value_1_date;
    this.value_1_float = data.value_1_float;
    this.value_1_integer = data.value_1_integer;
    this.value_1_boolean = data.value_1_boolean;
  }
}

export interface ITemplateDoubleItemForm extends TemplateSingleItem {
  value_2_char: string;
  value_2_text: string;
  value_2_time: string;
  value_2_date: string;
  value_2_float: number;
  value_2_integer: number;
  value_2_boolean: boolean;
}

export class TemplateDoubleItem
  extends TemplateSingleItem
  implements ITemplateDoubleItemForm
{
  public value_2_char: string;
  public value_2_text: string;
  public value_2_time: string;
  public value_2_date: string;
  public value_2_float: number;
  public value_2_integer: number;
  public value_2_boolean: boolean;

  constructor(data: Partial<TemplateDoubleItem> = {}) {
    super(data);

    this.value_2_char = data.value_2_char;
    this.value_2_text = data.value_2_text;
    this.value_2_time = data.value_2_time;
    this.value_2_date = data.value_2_date;
    this.value_2_float = data.value_2_float;
    this.value_2_integer = data.value_2_integer;
    this.value_2_boolean = data.value_2_boolean;
  }
}

export interface ITemplateTripeItemForm extends TemplateDoubleItem {
  value_3_char: string;
  value_3_text: string;
  value_3_time: string;
  value_3_date: string;
  value_3_float: number;
  value_3_integer: number;
  value_3_boolean: boolean;
}

export class TemplateTripeItem
  extends TemplateDoubleItem
  implements ITemplateTripeItemForm
{
  public value_3_char: string;
  public value_3_text: string;
  public value_3_time: string;
  public value_3_date: string;
  public value_3_float: number;
  public value_3_integer: number;
  public value_3_boolean: boolean;

  constructor(data: Partial<TemplateTripeItem> = {}) {
    super(data);

    this.value_3_char = data.value_3_char;
    this.value_3_text = data.value_3_text;
    this.value_3_time = data.value_3_time;
    this.value_3_date = data.value_3_date;
    this.value_3_float = data.value_3_float;
    this.value_3_integer = data.value_3_integer;
    this.value_3_boolean = data.value_3_boolean;
  }
}
