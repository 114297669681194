import { format } from "date-fns";
import { Boxed, Unboxed } from "ngrx-forms";
import {
  CommonApiValue,
  CommonFormValue,
  IMany2Many,
  IMany2One,
  IOne2Many,
  Many2Many,
  Many2One,
  One2Many,
  SyncStatus,
} from "./api.model";
import { DiveOperationCommon, DiveOperationState } from "./common.model";
import { ITimesheetForm, Timesheet } from "./timesheet.models";

export const DIVE_OPERATION_MODEL = "dive.operation";

export interface IDiveOperationForm {
  job_id: Boxed<IMany2One>;
  partner_id: Boxed<IMany2One>;
  site_id: Boxed<IMany2One>;
  supervisor_id: Boxed<IMany2One>;
  diver_ids: Boxed<IMany2Many<IMany2One>>;
  date_start: string;
  time_sheet_ids: IOne2Many<ITimesheetForm>;
  time_sheet_comment: string;
  state?: DiveOperationState;
}

export class DiveOperation
  extends DiveOperationCommon
  implements Unboxed<IDiveOperationForm>
{
  public time_sheet_comment: string;
  public time_sheet_ids: One2Many<Timesheet>;

  public sync_status: SyncStatus;
  public sync_errors: unknown[];

  constructor(data: Partial<DiveOperation> = {}) {
    super(data);

    this.time_sheet_comment = data.time_sheet_comment;
    this.time_sheet_ids = new One2Many(
      data.time_sheet_ids
        ? data.time_sheet_ids.map((timesheet) => new Timesheet(timesheet))
        : []
    );

    this.sync_status = data.sync_status ?? "success";
    this.sync_errors = data.sync_errors ?? [];
  }
}

export class DiveOperationFormValue
  extends CommonFormValue<DiveOperationFormValue>
  implements IDiveOperationForm
{
  public job_id: Boxed<IMany2One>;
  public partner_id: Boxed<IMany2One>;
  public site_id: Boxed<IMany2One>;
  public supervisor_id: Boxed<IMany2One>;
  public diver_ids: Boxed<IMany2Many<IMany2One>>;
  public date_start: string;
  public time_sheet_ids: IOne2Many<ITimesheetForm>;
  public time_sheet_comment: string;
  public state?: DiveOperationState;

  constructor(data: Partial<Unboxed<IDiveOperationForm>> = {}) {
    super();

    this.setMany2One("job_id", data);
    this.setMany2One("partner_id", data);
    this.setMany2One("site_id", data);
    this.setMany2One("supervisor_id", data);
    this.setMany2Many("diver_ids", data);
    this.setProperty("date_start", data);
    this.setProperty("state", data);
    this.setProperty("date_start", data, format(new Date(), "yyyy-MM-dd"));
    this.setOne2Many("time_sheet_ids", data);
    this.setProperty("time_sheet_comment", data);
  }
}

export class DiveOperationApiValue
  extends CommonApiValue<DiveOperationApiValue>
  implements Unboxed<IDiveOperationForm>
{
  public job_id: Many2One;
  public partner_id: Many2One;
  public site_id: Many2One;
  public supervisor_id: Many2One;
  public diver_ids: Many2Many<Many2One>;
  public date_start: string;
  public time_sheet_ids: One2Many<Timesheet>;
  public time_sheet_comment: string;
  public state?: DiveOperationState;

  constructor(data: Partial<IDiveOperationForm> = {}) {
    super();

    this.setMany2One("job_id", data);
    this.setMany2One("partner_id", data);
    this.setMany2One("site_id", data);
    this.setMany2One("supervisor_id", data);
    this.setMany2Many("diver_ids", data);
    this.setProperty("date_start", data);
    this.setProperty("state", data);
    this.setOne2Many("time_sheet_ids", data);
    this.setProperty("time_sheet_comment", data);
  }
}
