export interface AuthTokens {
  accessToken: string;
  refreshToken?: string;
}

export class AuthUser {
  id: number;
  login: string;
  name: string;
  email: string;
  role: string;
  company_id: number;
  company_ids: { id: number; name: string }[];

  constructor(
    data: {
      id?: number;
      login?: string;
      name?: string;
      email?: string;
      dopa_role?: string;
      company_id?: number;
      company_ids?: [{ id: number; name: string }];
    } = {}
  ) {
    this.id = data.id;
    this.login = data.login;
    this.name = data.name;
    this.email = data.email;
    this.role = data.dopa_role;
    this.company_id = data.company_id;
    this.company_ids = data.company_ids || [];
  }
}

export class TokenRefreshError extends Error {}
