import {
  ApiId,
  CommonApiValue,
  CommonFormValue,
  IOne2Many,
  IOne2ManyItem,
  Many2One,
  One2Many,
  One2ManyItem,
} from "@app/models";
import { Unboxed } from "ngrx-forms";
import { DiveOperationCommon } from "./common.model";

export const RISK_ASSESSMENT_MODEL = "risk.assessment";

export interface IRiskAssessmentSigneeForm extends IOne2ManyItem {
  position: string;
  signature: string;
}

export interface IRiskAssessmentChangeForm extends IOne2ManyItem {
  ref: string;
  supervisor_signature: string;
  signer_name: string;
  signer_position: string;
  signer_signature: string;
}

export interface IRiskAssessmentForm {
  operation_id?: ApiId;
  signee_ids?: IOne2Many<IRiskAssessmentSigneeForm>;
  change_ids?: IOne2Many<IRiskAssessmentChangeForm>;
}

export class RiskAssessmentSignee
  extends One2ManyItem
  implements IRiskAssessmentSigneeForm
{
  public position: string;
  public signature: string;
  constructor(data: Partial<RiskAssessmentSignee> = {}) {
    super(data);

    if (!this.hasOwnProperty("name")) {
      this.name = null;
    }

    this.position = data.position;
    this.signature = data.signature;
  }
}

export class RiskAssessmentChange
  extends One2ManyItem
  implements IRiskAssessmentChangeForm
{
  public ref: string;
  public supervisor_id: Many2One;
  public supervisor_signature: string;
  public signer_name: string;
  public signer_position: string;
  public signer_signature: string;

  constructor(data: Partial<RiskAssessmentChange> = {}) {
    super(data);

    if (!this.hasOwnProperty("name")) {
      this.name = null;
    }

    this.ref = data.ref;
    this.supervisor_id = data.supervisor_id
      ? new Many2One(data.supervisor_id)
      : null;
    this.supervisor_signature = data.supervisor_signature;
    this.signer_name = data.signer_name;
    this.signer_position = data.signer_position;
    this.signer_signature = data.signer_signature;
  }
}

export class RiskAssessment
  extends DiveOperationCommon
  implements IRiskAssessmentForm
{
  public signer_name: string;
  public signer_position: string;
  public signer_signature: string;
  public signee_ids: One2Many<RiskAssessmentSignee>;
  public change_ids: One2Many<RiskAssessmentChange>;

  constructor(data: Partial<RiskAssessment> = {}) {
    super(data);

    this.signer_name = data.signer_name;
    this.signer_position = data.signer_position;
    this.signer_signature = data.signer_signature;

    this.signee_ids = new One2Many(
      data.signee_ids
        ? data.signee_ids.map((signee) => new RiskAssessmentSignee(signee))
        : []
    );

    this.change_ids = new One2Many(
      data.change_ids
        ? data.change_ids.map((change) => new RiskAssessmentChange(change))
        : []
    );
  }
}

export class RiskAssessmentFormValue
  extends CommonFormValue<RiskAssessmentFormValue>
  implements IRiskAssessmentForm
{
  public signee_ids: IOne2Many<IRiskAssessmentSigneeForm>;
  public change_ids: IOne2Many<IRiskAssessmentChangeForm>;

  constructor(data: Partial<Unboxed<IRiskAssessmentForm>> = {}) {
    super();

    this.setOne2Many("signee_ids", data);
    this.setOne2Many("change_ids", data);
  }
}

export class RiskAssessmentApiValue
  extends CommonApiValue<RiskAssessmentApiValue>
  implements Unboxed<IRiskAssessmentForm>
{
  public signee_ids: One2Many<RiskAssessmentSignee>;
  public change_ids: One2Many<RiskAssessmentChange>;

  constructor(data: Partial<IRiskAssessmentForm> = {}) {
    super();

    this.setOne2Many("signee_ids", data);
    this.setOne2Many("change_ids", data);
  }
}
