import { Boxed } from "ngrx-forms";
import { AppRecord, IMany2One } from "./api.model";

export interface ICompanyForm {
  company_id: Boxed<IMany2One>;
}

export class Company extends AppRecord {
  constructor(data: Partial<Company> = {}) {
    super(data);
  }
}
